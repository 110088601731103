/* #################### LAYOUT GRID #################### */
.layout {
  display: grid;
  grid-template-areas:
    "navigation"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  height: 100%;
}

.logo {
  grid-area: logo;
}

.navigation {
  grid-area: navigation;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
}

/* #################### COLUMN GRID #################### */
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2.5rem;
}

.col-1--desktop {
  grid-column-end: span 1;
}

.col-2--desktop {
  grid-column-end: span 2;
}

.col-3--desktop {
  grid-column-end: span 3;
}

.col-4--desktop {
  grid-column-end: span 4;
}

.col-5--desktop {
  grid-column-end: span 5;
}

.col-6--desktop {
  grid-column-end: span 6;
}

.col-7--desktop {
  grid-column-end: span 7;
}

.col-8--desktop {
  grid-column-end: span 8;
}

.col-9--desktop {
  grid-column-end: span 9;
}

.col-10--desktop {
  grid-column-end: span 10;
}

.col-11--desktop {
  grid-column-end: span 11;
}

.col-12--desktop {
  grid-column-end: span 12;
}

@media (max-width: $tablet) {
  .col-1--tablet {
    grid-column-end: span 1;
  }

  .col-2--tablet {
    grid-column-end: span 2;
  }

  .col-3--tablet {
    grid-column-end: span 3;
  }

  .col-4--tablet {
    grid-column-end: span 4;
  }

  .col-5--tablet {
    grid-column-end: span 5;
  }

  .col-6--tablet {
    grid-column-end: span 6;
  }

  .col-7--tablet {
    grid-column-end: span 7;
  }

  .col-8--tablet {
    grid-column-end: span 8;
  }

  .col-9--tablet {
    grid-column-end: span 9;
  }

  .col-10--tablet {
    grid-column-end: span 10;
  }

  .col-11--tablet {
    grid-column-end: span 11;
  }

  .col-12--tablet {
    grid-column-end: span 12;
  }
}

@media (max-width: $mobile) {
  .col-1--mobile {
    grid-column-end: span 1;
  }

  .col-2--mobile {
    grid-column-end: span 2;
  }

  .col-3--mobile {
    grid-column-end: span 3;
  }

  .col-4--mobile {
    grid-column-end: span 4;
  }

  .col-5--mobile {
    grid-column-end: span 5;
  }

  .col-6--mobile {
    grid-column-end: span 6;
  }

  .col-7--mobile {
    grid-column-end: span 7;
  }

  .col-8--mobile {
    grid-column-end: span 8;
  }

  .col-9--mobile {
    grid-column-end: span 9;
  }

  .col-10--mobile {
    grid-column-end: span 10;
  }

  .col-11--mobile {
    grid-column-end: span 11;
  }

  .col-12--mobile {
    grid-column-end: span 12;
  }
}
