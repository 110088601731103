.datalist {
  border: 0.1rem solid $black-75;
  border-collapse: separate;
  border-radius: 1.2rem;
  display: inline-block;
  min-width: 60rem;

  &--drag {
    border: 0.1rem solid $black-75;
    border-collapse: separate;
    border-radius: 1.2rem;
  }

  &__row {
    display: flex;
  }

  &__header {
    background: $white-50;
    border-bottom: 0.1rem solid $white-30;
    padding: 1.5rem;
    text-align: left;
    position: relative;

    &--first {
      border-top-left-radius: 1.2rem;
    }

    &--last {
      border-bottom: none;
      border-bottom-left-radius: 1.2rem;
    }

    &--drag {
      flex-basis: 12%;
    }
  }

  &__item {
    background: $white-70;
    border-bottom: 0.1rem solid $white-30;
    min-height: 4rem;
    width: 100%;
    padding: 1.5rem;
    // word-break: break-all;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;

    &--first {
      border-top-right-radius: 1.2rem;
    }

    &--last {
      border-bottom: none;
      border-bottom-right-radius: 1.2rem;
    }
  }
}

@media (max-width: $tablet) {
  .datalist {
    min-width: 100%;
  }
}
