/* ############### TOP CONTAINER ###############*/
.dnd__drag-grid {
  min-height: 4rem;
  position: relative;
}

.dnd__column {
  display: inline-block;
}

/* ############### BOTTOM CONTAINER ###############*/
.dnd__drop-container {
  background: $black-80;
  border: 1px solid $black-75;
  border-bottom: 1px solid $black-50;
  border-radius: 12px;
  border-top: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4), 0 -1px 1px $black-50,
    0 1px 0 $black-50;
  padding: 10px;
}

.dnd__drop-target {
  position: relative;
  min-height: 65px;
  margin-bottom: -2.5rem;
}

/* ############### DELETE CONTAINER ###############*/
.dnd__delete-target {
  width: 50px;
  min-height: 56px;
  background: rgba(0, 0, 0, 0);
  color: white;
  position: relative;
  background-image: url("../assets/images/trash-empty.png");
  background-size: cover;
  box-sizing: border-box;
  margin: 0 auto 10px auto;
}

.dnd__delete-hover {
  background-image: url("../assets/images/trash-hover.png") !important;
}

.dnd__delete-bg {
  background: linear-gradient(rgba(0, 0, 0, 0), $black-30) !important;
}

/* ############### DRAGGABLE ITEMS ###############*/
.dnd__draggable-item {
  background-color: $white-70;
  border: 0.1rem solid $black-60;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.7rem 1.5rem;
  transition: all 50ms ease-in-out;
  user-select: none;
  display: inline-block;
}

.dnd__draggable-item:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: scale(1.12, 1.12);
}

.dnd__draggable-item:focus {
  box-shadow: 0 0 0 0.1rem $blue-50;
  outline: none;
}

.dnd__draggable-container {
  display: flex;
  align-items: center;
}

.dnd__draggable-icon {
  margin-right: 1.5rem;
  color: $black-80;
}

.dnd__item {
  position: absolute;
  z-index: 1;
  margin-bottom: 2.5rem;
  margin-right: 0.5rem;
}

.dnd__item:focus .dnd__draggable-item {
  box-shadow: 0 0 0 0.2rem $blue-50;
  outline: none;
}

.dnd__item.muuri-item-dragging {
  z-index: 10;
}

.dnd__item.muuri-item-releasing {
  z-index: 10;
}

.dnd__item.muuri-item-hidden {
  z-index: 0;
}

.dnd__item.muuri-item-dragging .dnd__item-content .dnd__draggable-item {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: scale(1, 1);
}

/* ############### POSITIONS ###############*/
.dnd__position {
  background: $black-50;
  border-radius: 1.2rem;
  color: #f0f8ff;
  cursor: pointer;
  font-size: 25px;
  height: 43px;
  line-height: 43px;
  margin-left: 15px;
  text-align: center;
  width: 50px;
}

.dnd__item-content > :first-child:focus .dnd__position {
  box-shadow: 0 0 0 5px #3cd1ca;
  outline: none;
}

/* ############### PLACEHOLDER ###############*/
.dnd__placeholder {
  background: $black-70;
  border-radius: 8px;
  height: 43px;
  /* width: 100px; */
  border: 1px solid transparent;
  border-bottom: 1px solid $black-80;
  border-top: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px $black-80,
    0 1px 0 $black-80;
  text-align: center;
  line-height: 100px;
}

.dnd__placeholder-icon {
  color: #535253;
  vertical-align: 0.5em;
}

div.muuri-item-placeholder {
  /* border: 2px #fff dashed; */
}

/* ############### REACT MODAL ###############*/
.edit-word-input {
  font-size: 18px;
  padding: 10px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background: rgb(247, 247, 247);
}

.edit-word-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.edit-word-btn {
  flex-basis: 48%;
}

.ReactModal__Content {
  background: rgb(223, 223, 223);
  border-radius: 1.2rem;
  box-shadow: 0 1.5rem 3rem rgba(74, 81, 84, 0.1),
    0 0.5rem 1.5rem rgba(0, 0, 0, 0.2);
  padding: 2.5rem;
  z-index: 10;
}

.ReactModal__Content:focus {
  outline: none;
}

.ReactModal__message-content {
  width: 50rem;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  overflow: auto;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ReactModal__close-btn {
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 1170px) {
  .ReactModal__dictionary-content {
    padding: 1.5rem;
    box-sizing: border-box;
  }
}

.ReactModal__Overlay {
  background: rgba(34, 37, 40, 0.75);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 150ms ease-in-out;
  z-index: 10;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__content--desc {
  text-align: center;
}

.ReactModal__content--desc-alert {
  max-width: 40rem;
}

.highlightedItem .dnd__draggable-item {
  border: solid $active-color;
  color: $active-color;
}

.highlightedItem svg.dnd__draggable-icon {
  color: $active-color;
}

.activeItem {
  border: solid $visited-color;
  color: $visited-color;
}

/* ############### PHRASE TRAY ###############*/

.phraseContainer {
  background-color: $black-50;
  position: sticky;
  border-radius: 0 0 2.5rem 2.5rem;
  box-shadow: 0 1.9rem 3.8rem rgba(0, 0, 0, 0.3),
    0 1.5rem 1.2rem rgba(0, 0, 0, 0.22);
  max-width: 1170px;
  padding-bottom: 4rem;
  top: 0;
  margin: 0 auto -2.2rem;
  z-index: 2;
}

.phraseContainer .dnd__item {
  margin: 0;
}

.phraseContainer .dnd__drop-container {
  background-color: $orange-80;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  max-width: 1170px;
}

.phraseButtonGroup {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.phraseButtonGroup .phraseUndoButton {
  padding: 0.3rem 1rem;
  background-color: $white-70;
  border: 0.1rem solid $black-20;
  border-radius: 1.2rem;
  transition: background-color 50ms ease-in-out;
  margin-right: 0.5rem;
  &:hover {
    color: $black-20;
    background-color: $red-60;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }
}

.phraseButtonGroup .phraseClearButton {
  padding: 0.3rem 1rem;
  background-color: $white-70;
  border: 0.1rem solid $black-20;
  border-radius: 1.2rem;
  transition: background-color 50ms ease-in-out;
  &:hover {
    color: $black-20;
    background-color: $red-60;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }
}

.phraseBuilderTitle {
  color: $white-70;
  font-size: large;
  font-weight: bold;
  border: none;
  text-align: center;
}

.phraseHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 10px;
}

.phraseBuilderMultiSelectButton {
  margin-top: 33px;
  background-color: $white-70;
  border: 0.1rem solid $black-20;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.3rem 1rem;
  transition: background-color 50ms ease-in-out;

  &:hover {
    background-color: $white-50;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }

  &__active {
    background-color: $green-50;
    color: $white-70;

    &:hover {
      color: $white-70;
      background-color: $green-40;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }
}

.yourAnswerClearBtn {
  margin-top: 0;
  background-color: $white-70;
  border: 0.1rem solid $black-20;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.3rem 1rem;
  transition: background-color 50ms ease-in-out;

  &:hover {
    color: $black-20;
    background-color: $red-60;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }
}

.submission-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    display: block;
  }
}

.prost {
  width: 300px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .phraseContainer {
    background-color: $black-50;
    position: sticky;
    border-radius: 0;
    box-shadow: none;
    padding-bottom: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 0;
    margin-bottom: -1.8rem;
  }

  .phraseContainer .dnd__item {
    margin: 0;
  }

  .phraseContainer .dnd__drop-container {
    padding-bottom: 3px;
    padding-top: 7px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .phraseButtonGroup {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .phraseButtonGroup .phraseUndoButton {
    padding: 0.3rem 1rem;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    transition: background-color 50ms ease-in-out;
    margin-right: 0.5rem;
    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }

  .phraseButtonGroup .phraseClearButton {
    padding: 0.3rem 1rem;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    transition: background-color 50ms ease-in-out;
    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }

  .phraseBuilderTitle {
    color: $white-70;
    font-size: large;
    font-weight: bold;
    border: none;
    text-align: center;
    justify-content: flex-start;
  }

  .yourAnswerClearBtn {
    margin-top: 0;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0.3rem 1rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }
}

@media (max-width: 450px) {
  .phraseContainer {
    background-color: $black-50;
    position: sticky;
    border-radius: 0;
    box-shadow: none;
    padding-bottom: 1.3rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 0;
  }

  .phraseContainer .dnd__item {
    margin: 0;
  }

  .phraseContainer .dnd__drop-container {
    padding-bottom: 1px;
    padding-top: 8px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .phraseButtonGroup {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .phraseButtonGroup .phraseUndoButton {
    padding: 0.3rem 1rem;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    transition: background-color 50ms ease-in-out;
    margin-right: 0.5rem;
    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }

  .phraseButtonGroup .phraseClearButton {
    padding: 0.3rem 1rem;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    transition: background-color 50ms ease-in-out;
    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }

  .phraseBuilderTitle {
    color: $white-70;
    font-size: large;
    font-weight: bold;
    border: none;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .phraseHeader {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }

  .yourAnswerClearBtn {
    margin-top: 0;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0.3rem 1rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }

  .prost {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
}
