/* vietnamese */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBiAJoI3ZKyHaQfhf.woff")
    format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBiEJoI3ZKyHaQfhf.woff")
    format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8JoI3ZKyHaQQ.woff")
    format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QBiAJpp_c.woff2")
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QBiEJpp_c.woff2")
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/worksans/v7/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QBi8Jpg.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Work Sans", sans-serif;
  font-size: $desktop-md-font-size;
  font-weight: 500;
  line-height: $desktop-md-line-height;
}

h1 {
  color: $white-70;
  font-size: $desktop-h1-font-size;
  line-height: $desktop-h1-line-height;
  margin-top: 0;
}

h2 {
  color: $black-40;
  font-size: $desktop-h2-font-size;
  font-weight: 700;
  line-height: $desktop-h2-line-height;
  margin: 0;
}

p {
  margin-bottom: $desktop-md-font-size;
}

input,
select,
button,
textarea {
  color: $black-40;
  font-family: “Work Sans”, sans-serif;
  font-size: $desktop-md-font-size;
  font-weight: 500;
  line-height: $desktop-md-line-height;
}

.bold--lg {
  color: $black-40;
  font-size: $desktop-lg-font-size;
  font-weight: 700;
  line-height: $desktop-lg-line-height;
}

.bold--md {
  color: $black-40;
  font-size: $desktop-md-font-size;
  font-weight: 600;
  line-height: $desktop-md-line-height;
}

.bold--sm {
  color: $black-40;
  font-size: $desktop-sm-font-size;
  font-weight: 600;
  line-height: $desktop-sm-line-height;
}

.reg--lg {
  font-size: $desktop-lg-font-size;
  line-height: $desktop-lg-line-height;
}

.reg--md {
  font-size: $desktop-md-font-size;
  line-height: $desktop-md-line-height;
}

.reg--sm {
  font-size: $desktop-sm-font-size;
  line-height: $desktop-sm-line-height;
}

.title {
  color: $white-70;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: $desktop-subtitle-font-size;
  font-weight: 600;
  line-height: $desktop-subtitle-line-height;
}

/* ################# MEDIA QUERY FOR TABLETS ################# */
@media (max-width: $tablet) {
  body {
    font-size: $tablet-md-font-size;
    line-height: $tablet-md-line-height;
  }

  h1 {
    font-size: $tablet-h1-font-size;
    line-height: $tablet-h1-line-height;
  }

  h2 {
    font-size: $tablet-h2-font-size;
    line-height: $tablet-h2-line-height;
  }

  p {
    margin-bottom: $tablet-md-font-size;
  }

  input,
  select,
  button,
  textarea {
    font-size: $tablet-md-font-size;
    line-height: $tablet-md-line-height;
  }

  .bold--lg {
    font-size: $tablet-lg-font-size;
    line-height: $tablet-lg-line-height;
  }

  .bold--md {
    font-size: $tablet-md-font-size;
    line-height: $tablet-md-line-height;
  }

  .bold--sm {
    font-size: $tablet-sm-font-size;
    line-height: $tablet-sm-line-height;
  }

  .reg--lg {
    font-size: $tablet-lg-font-size;
    line-height: $tablet-lg-line-height;
  }

  .reg--md {
    font-size: $tablet-md-font-size;
    line-height: $tablet-md-line-height;
  }

  .reg--sm {
    font-size: $tablet-sm-font-size;
    line-height: $tablet-sm-line-height;
  }
}

/* ################# MEDIA QUERY FOR SMARTPHONE ################# */
@media (max-width: $mobile) {
  body {
    font-size: $mobile-md-font-size;
    line-height: $mobile-md-line-height;
  }

  h1 {
    font-size: $mobile-h1-font-size;
    line-height: $mobile-h1-line-height;
  }

  h2 {
    font-size: $mobile-h2-font-size;
    line-height: $mobile-h2-line-height;
  }

  p {
    margin-bottom: $mobile-md-font-size;
  }

  input,
  select,
  button,
  textarea {
    font-size: $mobile-md-font-size;
    line-height: $mobile-md-line-height;
  }

  .bold--lg {
    font-size: $mobile-lg-font-size;
    line-height: $mobile-lg-line-height;
  }

  .bold--md {
    font-size: $mobile-md-font-size;
    line-height: $mobile-md-line-height;
  }

  .bold--sm {
    font-size: $mobile-sm-font-size;
    line-height: $mobile-sm-line-height;
  }

  .reg--lg {
    font-size: $mobile-lg-font-size;
    line-height: $mobile-lg-line-height;
  }

  .reg--md {
    font-size: $mobile-md-font-size;
    line-height: $mobile-md-line-height;
  }

  .reg--sm {
    font-size: $mobile-sm-font-size;
    line-height: $mobile-sm-line-height;
  }
}
