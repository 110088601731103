.filter {
  background: $white-50;
  border: 0.1rem solid transparent;
  border-bottom: 0.1rem solid $white-40;
  border-radius: 1.2rem;
  box-shadow: inset 0 0.1rem 0.2rem rgba(0, 0, 0, 0.39), 0 -0.1rem 0.1rem #fff,
    0 0.1rem 0 #fff;
  border-top: none;

  &__name {
    padding: 2.5rem 2.5rem 0 2.5rem;
  }

  &__name-container {
    display: flex;
    justify-content: space-between;
  }

  &__category {
    padding: 1.5rem 2.5rem;
  }

  &__hr {
    border: 0;
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.9);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.3);
    height: 0;
    margin: 0;
  }

  &__btn-container {
    margin-top: 1.5rem;
    padding: 0 2.5rem 2.5rem 2.5rem;
  }

  &__close {
    background: none;
    border: none;
    border-top-right-radius: 1.2rem;
    cursor: pointer;
    padding: 0 2.5rem;

    &:focus {
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }

  &__accordion-btn {
    background: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    padding: 0;
  }
}

@media (max-width: $tablet) {
  .filter__name {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }

  .filter__category {
    padding: 1.5rem;
  }

  .filter__btn-container {
    padding: 0 1.5rem 2.5rem 1.5rem;
  }

  .filter__close {
    padding: 0 1.5rem;
  }
}

.search-bar {
  &__container {
    width: 100%;
    box-sizing: border-box;
  }

  &__input {
    width: 100%;
  }

  &--mobile {
    display: none;
  }
}

.search-bar__input > .react-autosuggest__container > .react-autosuggest__input {
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 0rem;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 0rem;
}

.search-bar__input
  > .react-autosuggest__container
  > .react-autosuggest__input--focused {
  outline: none;
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60,
    -0.1rem 0 0 0 $blue-60;
}

.search-bar__input
  > .react-autosuggest__container
  > .react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: $mobile) {
  .search-bar--desktop {
    display: none;
  }

  .search-bar--mobile {
    display: block;
  }
}
