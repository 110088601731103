/* #################### BTN PRIMARY #################### */

.primary-btn {
  background-color: $orange-80;
  border: 0.1rem solid $black-60;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.7rem 3rem;
  transition: background-color 50ms ease-in-out;

  &:hover {
    background-color: $orange-70;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }

  &:focus {
    background-color: $orange-50;
    box-shadow: 0 0 0 0.1rem $blue-50;
    outline: none;
  }

  &--search {
    background-color: $orange-80;
    border: 0.1rem solid $black-60;
    border-radius: 0 1.2rem 1.2rem 0;
    cursor: pointer;
    height: 4.3rem;
    padding: 0.7rem 3rem;
    margin: 0;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $orange-70;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $orange-50;
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        0.1rem 0 0 0 $blue-50;
      outline: none;
    }
  }
}

@media (max-width: $tablet) {
  .primary-btn--search {
    height: 4rem;
  }
}

@media (max-width: $mobile) {
  .primary-btn--search {
    height: 3.7rem;
  }
}

/* #################### BTN SECONDARY #################### */

.secondary-btn {
  background-color: $white-70;
  border: 0.1rem solid $black-60;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.7rem 3rem;
  transition: background-color 50ms ease-in-out;

  &:hover {
    background-color: $white-30;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }

  &:focus {
    background-color: $black-80;
    box-shadow: 0 0 0 0.1rem $blue-50;
    color: $black-20;
    outline: none;
  }

  &--selected {
    background-color: $black-80;
    border: 0.1rem solid $black-60;
    border-radius: 1.2rem;
    color: $black-20;
    cursor: pointer;
    padding: 0.7rem 3rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $black-75;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $black-75;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }
}

/* #################### BTN GROUP #################### */

.group-btn {
  &__left {
    background-color: $white-70;
    border-bottom: 0.1rem solid $black-60;
    border-left: 0.1rem solid $black-60;
    border-radius: 1.2rem 0 0 1.2rem;
    border-right: 0.1rem solid $black-70;
    border-top: 0.1rem solid $black-60;
    cursor: pointer;
    margin: 0;
    padding: 0.7rem 3rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $white-30;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $black-80;
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        -0.1rem 0 0 0 $blue-50;
      color: $black-20;
      outline: none;
    }

    &--selected {
      background-color: $black-50;
      border-bottom: 0.1rem solid $black-60;
      border-radius: 1.2rem 0 0 1.2rem;
      border-right: 0.1rem solid $black-70;
      border-left: 0.1rem solid $black-60;
      border-top: 0.1rem solid $black-60;
      color: $white-70;
      cursor: pointer;
      margin: 0;
      padding: 0.7rem 3rem;
      transition: background-color 50ms ease-in-out;

      &:hover {
        background-color: $black-60;
        box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
      }

      &:focus {
        background-color: $black-60;
        box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
          -0.1rem 0 0 0 $blue-50;
        outline: none;
      }
    }
  }

  &__mid {
    background-color: $white-70;
    border-bottom: 0.1rem solid $black-60;
    border-left: none;
    border-right: 0.1rem solid $black-70;
    border-top: 0.1rem solid $black-60;
    cursor: pointer;
    margin: 0;
    padding: 0.7rem 3rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $white-30;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $black-80;
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50;
      color: $black-20;
      outline: none;
    }

    &--selected {
      background-color: $black-50;
      border-bottom: 0.1rem solid $black-60;
      border-left: none;
      border-right: 0.1rem solid $black-70;
      border-top: 0.1rem solid $black-60;
      color: $white-70;
      cursor: pointer;
      margin: 0;
      padding: 0.7rem 3rem;
      transition: background-color 50ms ease-in-out;

      &:hover {
        background-color: $black-60;
        box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
      }

      &:focus {
        background-color: $black-60;
        box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50;
        outline: none;
      }
    }
  }

  &__right {
    background-color: $white-70;
    border-bottom: 0.1rem solid $black-60;
    border-left: none;
    border-radius: 0 1.2rem 1.2rem 0;
    border-right: 0.1rem solid $black-60;
    border-top: 0.1rem solid $black-60;
    cursor: pointer;
    margin: 0;
    padding: 0.7rem 3rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $white-30;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $black-80;
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        0.1rem 0 0 0 $blue-50;
      color: $black-20;
      outline: none;
    }

    &--selected {
      background-color: $black-50;
      border-bottom: 0.1rem solid $black-60;
      border-left: none;
      border-radius: 0 1.2rem 1.2rem 0;
      border-right: 0.1rem solid $black-60;
      border-top: 0.1rem solid $black-60;
      color: $white-70;
      cursor: pointer;
      margin: 0;
      padding: 0.7rem 3rem;
      transition: background-color 50ms ease-in-out;

      &:hover {
        background-color: $black-60;
        box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
      }

      &:focus {
        background-color: $black-60;
        box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
          0.1rem 0 0 0 $blue-50;
        outline: none;
      }
    }
  }
}

/* #################### NEGATIVE BTN #################### */

.negative-btn {
  &--high-contrast {
    background-color: $red-50;
    border: 0.1rem solid $black-60;
    border-radius: 1.2rem;
    color: $white-70;
    cursor: pointer;
    padding: 0.7rem 3rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $red-40;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $red-30;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }

  &--low-contrast {
    background-color: rgba(0, 0, 0, 0);
    border-width: 0.2rem;
    border-style: solid;
    border-color: $red-50;
    border-radius: 1.2rem;
    color: $red-50;
    cursor: pointer;
    padding: 0.7rem 3rem;
    transition: color 50ms ease-in-out, border-color 50ms ease-in-out;

    &:hover {
      color: $red-40;
      border-width: 0.2rem;
      border-style: solid;
      border-color: $red-40;
      box-shadow: inset 0 -0.2rem 0 rgba(196, 27, 32, 0.37);
    }

    &:focus {
      color: $red-30;
      border-width: 0.2rem;
      border-style: solid;
      border-color: $red-30;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }
}

/* #################### BTN DRAGGABLE #################### */

.draggable-btn {
  background-color: $white-70;
  border: 0.1rem solid $black-60;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.7rem 3rem;
  transition: all 50ms ease-in-out;
  user-select: none;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transform: scale(1.12, 1.12);
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem $blue-50;
    outline: none;
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transform: scale(1.05, 1.05);
  }
}

/* #################### BTN FILTER #################### */

.filter-btn {
  background-color: $white-70;
  border: 0.1rem solid $black-60;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 0.2rem 2rem;
  transition: background-color 50ms ease-in-out;

  &:hover {
    background-color: $white-30;
    box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
  }

  &:focus {
    background-color: $black-80;
    color: $black-20;
    box-shadow: 0 0 0 0.1rem $blue-50;
    outline: none;
  }

  &--selected {
    background-color: $black-80;
    border: 0.1rem solid $black-60;
    border-radius: 1.2rem;
    color: $black-20;
    cursor: pointer;
    padding: 0.2rem 2rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $black-75;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $black-75;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }

  &--applied {
    background-color: $white-70;
    border: 0.1rem solid $black-60;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0.2rem 2rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $red-70;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $red-70;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }

  &--applied-clear-all {
    background-color: $red-70;
    border: 0.1rem solid $black-60;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0.2rem 2rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
      color: $black-20;
    }

    &:focus {
      background-color: $red-70;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }
}

/* #################### BTN LINK #################### */

.link-btn {
  &--light {
    color: $orange-40;
    cursor: pointer;
    text-decoration: underline;
    transition: color 50ms ease-in-out;
    background: none;
    border: none;

    &:hover {
      color: $orange-30;
    }

    &:focus {
      color: $orange-20;
      outline: 0.1rem solid $blue-50;
    }
  }

  &--dark {
    color: $orange-80;
    cursor: pointer;
    text-decoration: underline;
    transition: color 50ms ease-in-out;
    background: none;
    border: none;

    &:hover {
      color: $orange-70;
    }

    &:focus {
      color: $orange-50;
      outline: 0.1rem solid $blue-60;
    }
  }
}

/* #################### DISABLED #################### */

.disabled-btn {
  background: rgba(0, 0, 0, 0);
  border: 0.1rem solid $black-70;
  border-radius: 1.2rem;
  color: $black-70;
  cursor: pointer;
  padding: 0.7rem 3rem;

  &:focus {
    box-shadow: 0 0 0 0.1rem $blue-50;
    outline: none;
  }
}

/* #################### Focus color for card--dark #################### */
.card--dark .primary-btn:focus,
.card--dark .secondary-btn:focus,
.card--dark .secondary-btn--selected:focus,
.card--dark .draggable-btn:focus,
.card--dark .filter-btn:focus,
.card--dark .filter-btn--selected:focus,
.card--dark .filter-btn--applied:focus {
  box-shadow: 0 0 0 0.1rem $blue-60;
}

.card--dark .primary-btn--search:focus,
.card--dark .group-btn__right:focus,
.card--dark .group-btn__right--selected:focus {
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60,
    0.1rem 0 0 0 $blue-60;
}

.card--dark .group-btn__mid:focus,
.card--dark .group-btn__mid--selected:focus {
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60;
}

.card--dark .group-btn__left:focus,
.card--dark .group-btn__left--selected:focus {
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60,
    -0.1rem 0 0 0 $blue-60;
}

/* #################### Focus color for header #################### */
.header .primary-btn:focus,
.header .secondary-btn:focus,
.header .secondary-btn--selected:focus,
.header .draggable-btn:focus,
.header .filter-btn:focus,
.header .filter-btn--selected:focus,
.header .filter-btn--applied:focus {
  box-shadow: 0 0 0 0.1rem $blue-60;
}

.header .primary-btn--search:focus,
.header .group-btn__right:focus,
.header .group-btn__right--selected:focus {
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60,
    0.1rem 0 0 0 $blue-60;
}

.header .group-btn__mid:focus,
.header .group-btn__mid--selected:focus {
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60;
}

.header .group-btn__left:focus,
.header .group-btn__left--selected:focus {
  box-shadow: 0 -0.1rem 0 0 $blue-60, 0 0.1rem 0 0 $blue-60,
    -0.1rem 0 0 0 $blue-60;
}
