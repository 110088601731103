.searchbar {
  &__container {
    width: 100%;
    box-sizing: border-box;
  }

  &__search-bar {
    width: 100%;
  }

  &__filter--mobile {
    display: none;
  }
}

.wordlist {
  &__container {
    column-count: 2;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  &__item {
    break-inside: avoid;
    margin: auto;
    margin-bottom: 2.5rem;
    width: 100%;
    max-width: 25rem;
  }

  &__recommended {
    column-count: 3;
  }

  &__recommended-item {
    break-inside: avoid;
  }
}

@media (max-width: $tablet) {
  .wordlist__recommended {
    column-count: 2;
  }
}

@media (max-width: $mobile) {
  .searchbar__filter--desktop {
    display: none;
  }

  .searchbar__filter--mobile {
    display: block;
  }

  .wordlist__container {
    column-count: 1;
  }

  .wordlist__recommended {
    column-count: 1;
  }
}
