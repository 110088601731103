.error-page {
  background: $white-60;
}

.error-page h1 {
  color: $black-30;
}

.not-supported {
  &__red-alert {
    color: $red-30;
  }

  &__container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 0 1.5rem;
  }

  &__icon-container {
    display: inline-block;
    margin-bottom: 5rem;
    margin-right: 2.5rem;
    padding-top: 2.5rem;
    text-align: center;
    vertical-align: top;
  }

  &__content {
    display: inline-block;
    max-width: 80rem;
  }
}

@media (max-width: 1200px) {
  .not-supported__icon-container {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .not-supported__content {
    display: block;
    margin: 5rem auto;
  }
}

@media (max-width: $mobile) {
  .not-supported__btn {
    box-sizing: border-box;
    display: block;
    margin-right: 0;
    width: 100%;
  }
}
