label,
input {
  display: block;
}

/* ################# Fieldsets ################# */
legend {
  margin-bottom: 2.5rem;
}

textarea {
  border: 0.1rem solid $black-75;
  border-radius: 1.2rem;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 1.5rem;
  width: 100%;
  resize: none;
  overflow: hidden;
}

.legend--alt {
  float: left;
  width: 100%;
  padding: 0;

  + * {
    clear: both;
  }
}

@media (max-width: $tablet) {
  fieldset.inset {
    padding: 1.5rem;
  }
}
