.construct-sentence {
  margin: -3.3rem -3.3rem 0 -3.3rem;
  padding: 0 3.3rem 10px 3.3rem;
  background-color: $black-50;

  &__copy-btn {
    background: $black-40;
    border: none;
    // border-radius: 0 1.2rem 1.2rem 0;
    color: $white-70;
    cursor: pointer;
    margin-left: -1px;
    width: 5rem;

    &--active {
      background: $green-50;
      animation-name: copyBTN;
      animation-duration: 700ms;
    }
  }

  &__sentence {
    background: $white-60;
    // border-radius: 1.2rem 0 0 1.2rem !important;
    min-height: 2.5rem;
    padding: 1.5rem;
    width: 100%;
  }

  &__sentence-container {
    display: flex;
    // margin-bottom: 9rem;
    margin-top: 1rem;
  }

  &__sentence-label {
    color: $white-70;
    margin-bottom: 0;
  }

  &__sentence-overview-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@media (max-width: 1170px) {
  .construct-sentence {
    margin: -5rem -1rem 0 -1rem;
    padding: 0 1rem 1rem 1rem;
  }
}

@media (max-width: $mobile) {
  .construct-sentence {
    margin: -2.5rem -0.5rem 0 -0.5rem;
    padding: 0 1rem 1rem 1rem;
  }
}
