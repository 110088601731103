.tablist {
  border-radius: 1.2rem;

  &__header {
    background: $black-60;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    list-style-type: none;
    margin: 0;
    padding: 0 1.5rem;
  }

  &__list-item {
    display: inline-block;
    margin-right: 1rem;

    &:first-of-type {
      margin-left: 1rem;
    }
  }

  &__btn {
    background: $black-60;
    display: inline-block;
    border: none;
    color: $white-70;
    cursor: pointer;
    margin: 0;
    padding: 1.5rem 2.5rem;

    &:focus {
      outline: none;
    }

    &[aria-selected="true"] {
      background: $white-70;
      color: $black-60;
    }
  }

  &__section {
    background: $white-70;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    padding: 2.5rem;
  }
}

@media (max-width: $mobile) {
  .tablist {
    padding: 0;
  }
  .tablist__list-item {
    display: block;
    margin: 0;
  }

  .tablist__list-item:first-of-type {
    margin-left: 0;
  }

  .tablist__btn {
    width: 100%;
  }
}
