.alt__body {
  background: $white-60;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  padding: 0 3.3rem 2.5rem 3.3rem;
}

.main {
  background: $black-80;

  &__test {
    min-height: 90vh;
    overflow: auto;
  }

  &__body {
    background: $white-60;
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
    padding: 3.3rem;
  }

  &__container {
    border-radius: 2.5rem;
    box-shadow: 0 1.9rem 3.8rem rgba(0, 0, 0, 0.3),
      0 1.5rem 1.2rem rgba(0, 0, 0, 0.22);
    margin: 10rem auto;
    max-width: 1170px;
  }

  &__header {
    background: $black-50;
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    padding: 1.5rem 3.3rem;
  }
}

@media (max-width: 1170px) {
  .main {
    background: $white-60;
  }

  .alt__body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 1rem 2.5rem 1rem;
  }

  .main__body {
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5rem 1rem 5rem 1rem;
  }

  .main__container {
    border-radius: 0;
    box-shadow: none;
    margin: 0;

    &__title {
      text-align: center;
      white-space: normal;
    }
  }

  .main__container--body {
    background: $black-50;
  }

  .main__header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 3.5rem 1rem 1.5rem 1rem;
  }
}

@media (max-width: $mobile) {
  .main__body {
    padding: 1.5rem 0.5rem;
  }

  .main__header {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}
