/* ################# Cards ################# */
.card {
  background: $white-70;
  border: 0.1rem solid $white-50;
  border-radius: 1.2rem;
  display: inline-block;
  margin: 1.5rem 0;
  padding: 2.5rem;
}

.card--dark {
  background: $black-60;
  color: $white-70;
  border: 0.1rem solid $black-40;
  border-radius: 1.2rem;
  display: inline-block;
  margin: 1.5rem 0;
  padding: 2.5rem;
}

@media (max-width: $tablet) {
  .card {
    padding: 1.5rem;
  }

  .card--dark {
    padding: 1.5rem;
  }
}

/* ################# Shadows ################# */

.shadow--small {
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16),
    0 0.3rem 0.6rem rgba(0, 0, 0, 0.23);
}

.shadow--large {
  box-shadow: 0 1.9rem 3.8rem rgba(0, 0, 0, 0.3),
    0 1.5rem 1.2rem rgba(0, 0, 0, 0.22);
}

/* ################# Inset ################# */
.inset {
  background: $white-50;
  border: 0.1rem solid transparent;
  border-bottom: 0.1rem solid $white-40;
  border-radius: 1.2rem;
  border-top: none;
  box-shadow: inset 0 0.1rem 0.2rem rgba(0, 0, 0, 0.39),
    0 -0.1rem 0.1rem $white-60, 0 0.1rem 0 $white-60;
  padding: 2.5rem;
}

@media (max-width: $tablet) {
  .inset {
    padding: 1.5rem;
  }
}

/* ################# Display utils ################# */

.flex {
  display: flex !important;
}

.space-between {
  justify-content: space-between !important;
}

.wrap {
  flex-wrap: wrap !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.center-align {
  align-items: center !important;
}

.column {
  flex-direction: column;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.display-none {
  display: none;
}

.border-box {
  box-sizing: border-box !important;
}

.vertical-align--middle {
  vertical-align: middle !important;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.full-screen-height {
  min-height: 100vh !important;
  height: 100% !important;
}

.center-children {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.center-text {
  text-align: center !important;
}

.bundle {
  display: inline-block !important;
  page-break-inside: avoid !important;
}

.hidden {
  height: 1px !important;
  left: -10000px !important;
  overflow: hidden !important;
  position: absolute !important;
  top: auto !important;
  width: 1px !important;
}

.disabled-link {
  pointer-events: none;
}

.no-border {
  border: none !important;
}
.container {
  margin: 0px auto !important;
  max-width: 1170px !important;
}

/* Width that can fit navbar and container */
@media (max-width: 1500px) {
  .container {
    padding: 0 1.5rem !important;
  }
}

@media (max-width: $mobile) {
  .block--mobile {
    display: block !important;
  }

  .full-width-mobile {
    width: 100% !important;
  }
}

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0;
}

/* ################# Sizing Classes ################# */

/* Margin Bottom */
.mb-5 {
  margin-bottom: 0.5rem !important;
}

.mb-15 {
  margin-bottom: 1.5rem !important;
}

.mb-25 {
  margin-bottom: 2.5rem !important;
}

.mb-50 {
  margin-bottom: 5rem !important;
}

.mb-100 {
  margin-bottom: 10rem !important;
}

/* Margin Top */
.mt-5 {
  margin-top: 0.5rem !important;
}

.mt-15 {
  margin-top: 1.5rem !important;
}

.mt-25 {
  margin-top: 2.5rem !important;
}

.mt-50 {
  margin-top: 5rem !important;
}

.mt-100 {
  margin-top: 10rem !important;
}

/* Margin Left */
.ml-5 {
  margin-left: 0.5rem !important;
}

.ml-10 {
  margin-left: 1rem !important;
}

.ml-15 {
  margin-left: 1.5rem !important;
}

.ml-25 {
  margin-left: 2.5rem !important;
}

.ml-50 {
  margin-left: 5rem !important;
}

.ml-100 {
  margin-left: 10rem !important;
}

/* Margin Right */
.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 0.5rem !important;
}

.mr-10 {
  margin-right: 1rem !important;
}

.mr-15 {
  margin-right: 1.5rem !important;
}

.mr-25 {
  margin-right: 2.5rem !important;
}

.mr-50 {
  margin-right: 5rem !important;
}

.mr-100 {
  margin-right: 10rem !important;
}

/* Padding Bottom */
.pb-5 {
  padding-bottom: 0.5rem !important;
}

.pb-15 {
  padding-bottom: 1.5rem !important;
}

.pb-25 {
  padding-bottom: 2.5rem !important;
}

.pb-50 {
  padding-bottom: 5rem !important;
}

/* Padding Top */
.pt-5 {
  padding-top: 0.5rem !important;
}

.pt-15 {
  padding-top: 1.5rem !important;
}

.pt-25 {
  padding-top: 2.5rem !important;
}

.pt-50 {
  padding-top: 5rem !important;
}

/* Padding Left */
.pl-5 {
  padding-left: 0.5rem !important;
}

.pl-15 {
  padding-left: 1.5rem !important;
}

.pl-25 {
  padding-left: 2.5rem !important;
}

.pl-50 {
  padding-left: 5rem !important;
}

/* Padding Right */
.pr-5 {
  padding-right: 0.5rem !important;
}

.pr-15 {
  padding-right: 1.5rem !important;
}

.pr-25 {
  padding-right: 2.5rem !important;
}

.pr-50 {
  padding-right: 5rem !important;
}

@media (max-width: $tablet) {
  .p-15--tablet {
    padding: 1.5rem !important;
  }
}

@media (max-width: $mobile) {
  .mt-25--mobile {
    margin-top: 2.5rem !important;
  }

  .mb-25--mobile {
    margin-bottom: 2.5rem !important;
  }
}

.color-green-40 {
  color: $green-40;
}

.color-red-50 {
  color: $red-50;
}

.color-black-60 {
  color: $black-60;
}
