.footer {
  background: $black-40;
  color: $white-70;
  padding: 10px;

  &__logo {
    display: block;
    float: right;
  }

  &__report-btn {
    margin-top: 0;
    background-color: $white-70;
    border: 0.1rem solid $black-20;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0.7rem 3rem;
    transition: background-color 50ms ease-in-out;

    &:hover {
      color: $black-20;
      background-color: $red-60;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }
  }
}
