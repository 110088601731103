/* ################# Text Input ################# */
input.default[type="text"] {
  border: 0.1rem solid $black-75;
  border-radius: 1.2rem;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 1.5rem;
  width: 100%;
}

/* ################# Single Input Focus ################# */
input.default[type="text"]:focus {
  box-shadow: 0 0 0 0.1rem $blue-50;
  outline: none;
}
