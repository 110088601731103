/* ################# HR ################# */
hr {
  border-top: 1px solid $white-30;
  border-bottom: 1px solid $white-60;
  margin: 5rem 0 3.3rem 0;
  width: 100%;
}

/* ################# DESCRIPTION LIST ################# */
dd {
  margin-bottom: 1.1rem;
}

dd:last-child {
  margin-bottom: 0;
}
