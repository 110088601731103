.pagination {
  &__input {
    border: 0.1rem solid $black-75;
    border-radius: 1.2rem 0 0 1.2rem;
    box-sizing: border-box;
    display: inline-block;
    height: 4.2rem;
    padding: 0.5rem 1.5rem;
    width: 10rem;

    &:focus {
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        -0.1rem 0 0 0 $blue-50;
      outline: none;
    }
  }

  &__btn--right {
    background: $white-70;
    border: 0.1rem solid $black-60;
    border-radius: 0 1.2rem 1.2rem 0;
    color: $black-40;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    padding: 0.5rem 1.5rem;
    transition: all 50ms ease-in-out;
    vertical-align: middle;

    &:hover {
      background: $white-30;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background: $black-80;
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        0.1rem 0 0 0 $blue-50;
      color: $black-20;
      outline: none;
    }
  }

  &__btn--left {
    background: $white-70;
    border: 0.1rem solid $black-60;
    border-radius: 1.2rem 0 0 1.2rem;
    color: $black-40;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    padding: 0.5rem 1.5rem;
    transition: all 50ms ease-in-out;
    vertical-align: middle;

    &:hover {
      background: $white-30;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background: $black-80;
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        -0.1rem 0 0 0 $blue-50;
      color: $black-20;
      outline: none;
    }
  }

  &__btn--disabled-right {
    background: rgba(0, 0, 0, 0);
    border: 0.1rem solid $black-70;
    border-radius: 0 1.2rem 1.2rem 0;
    color: $black-70;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    padding: 0.5rem 1.5rem;
    transition: all 50ms ease-in-out;
    vertical-align: middle;

    &:focus {
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        0.1rem 0 0 0 $blue-50;
      outline: none;
    }
  }

  &__btn--disabled-left {
    background: rgba(0, 0, 0, 0);
    border: 0.1rem solid $black-70;
    border-radius: 1.2rem 0 0 1.2rem;
    color: $black-70;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    padding: 0.5rem 1.5rem;
    transition: all 50ms ease-in-out;
    vertical-align: middle;

    &:focus {
      box-shadow: 0 -0.1rem 0 0 $blue-50, 0 0.1rem 0 0 $blue-50,
        -0.1rem 0 0 0 $blue-50;
      outline: none;
    }
  }

  &__nav {
    float: right;

    &--mobile {
      display: flex;
    }
  }

  &__btn--mobile {
    flex-basis: 50%;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-1-of-3 {
      // flex-basis: 30.66%;
      margin: 0 1.5rem;
    }
  }
}

@media (max-width: 1200px) {
  .pagination__jump {
    float: right;
    margin-bottom: 5rem;
  }

  .pagination__grid-1-of-3 {
    flex-basis: 48%;
    margin: 0;
  }

  .tablet-full {
    flex-basis: 100%;
  }
}

/* Media query for tablet devices */
@media (max-width: $tablet) {
  .pagination__btn--right,
  .pagination__btn--disabled-right,
  .pagination__btn--left,
  .pagination__btn--disabled-left {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  .pagination__input {
    height: 3.9rem;
  }
}

/* Media query for smartphones */
@media (max-width: $mobile) {
  .pagination__btn--right,
  .pagination__btn--disabled-right,
  .pagination__btn--left,
  .pagination__btn--disabled-left {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .pagination__show {
    margin-bottom: 2.5rem;
  }

  .pagination__jump {
    float: none;
  }

  .pagination__input {
    height: 3.6rem;
  }

  .pagination__grid-1-of-3 {
    flex-basis: 100%;
  }
}
