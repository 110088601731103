.assignment-list {
  margin: 0 -3.3rem;

  &__container {
    display: flex;
    background: $white-60;

    &--selected {
      background: $white-30;

      &:active {
        background: $white-60;
      }
    }
  }

  &__header {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
  }

  &__list-item {
    display: inline-block;

    &--selected {
      background: $black-50;
    }
  }

  &__btn {
    background: $black-50;
    display: inline-block;
    border: none;
    color: $white-70;
    cursor: pointer;
    margin: 0;
    padding: 1.5rem 2.5rem;

    font-size: $desktop-md-font-size;
    font-weight: 600;
    line-height: $desktop-md-line-height;

    &[aria-selected="true"] {
      background: $white-60;
      color: $black-40;
      border-top-right-radius: 1.2rem;
      border-top-left-radius: 1.2rem;
    }
  }

  &__spacer {
    width: 100%;
    background: $black-50;

    &-last-item {
      border-bottom-left-radius: 1.2rem;
    }
  }

  &__body {
    padding: 5rem 3.3rem 2.5rem 3.3rem;
  }
}

.assignment-list__btn.assignment-list__btn--first[aria-selected="true"] {
  border-top-left-radius: 0;
}

.assignment-list__btn--before {
  border-bottom-right-radius: 1.2rem;
}

.assignment-list__btn--after {
  border-bottom-left-radius: 1.2rem;
}

.assignment-list__btn:focus {
  outline: none;
  background: $white-30;
  color: $black-40;
}

.assignment-list__btn:active {
  background: $black-50;
  color: $white-70;
}

.assignment-list__btn[aria-selected="true"]:active {
  background: $white-30;
  color: $black-40;
}

.assignment-list-passage-break {
  margin-bottom: 2.5rem;
}

li.assignment-list__list-item:last-child {
  display: none;
}

@media (max-width: 1170px) {
  .assignment-list {
    padding: 0;
    margin: 0 -0.5rem -0.5rem;
  }

  .assignment-list__body {
    padding: 5rem 1rem 2.5rem 1rem;
  }

  .assignment-list__container {
    margin: 0 -0.5rem -0.5rem;
  }
}

@media (max-width: $mobile) {
  .assignment-list {
    margin: 0;
    &__btn {
      &[aria-selected="true"] {
        background: $black-40;
        color: $white-60;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  .assignment-list__container {
    display: block;
    margin: 0 -0.5rem -0.5rem;
  }

  .assignment-list__body {
    padding: 2.5rem 0.5rem;
  }

  .assignment-list__list-item {
    width: 100%;
  }

  .assignment-list__btn {
    width: 100%;
  }

  .assignment-list__btn.assignment-list__btn--last[aria-selected="true"] {
    border-top-right-radius: 0;
  }

  .assignment-list__spacer,
  .assignment-list__spacer-last-item {
    display: none;
  }
}
