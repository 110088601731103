.nav {
  &__skip-link {
    background: $black-20;
    border-bottom-right-radius: 2.5rem;
    box-sizing: border-box;
    color: $orange-50;
    cursor: pointer;
    height: 6rem;
    left: 0;
    padding: 1.5rem 2.5rem;
    position: fixed;
    text-decoration: underline;
    top: -6rem;
    transition: top 100ms ease-in-out, color 50ms ease-in-out;
    z-index: 100;

    &:focus {
      box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16),
        0 0.3rem 0.6rem rgba(0, 0, 0, 0.23);
      outline: none;
      top: 0;
      transition: top 100ms ease-in-out;
    }
  }

  &__logo {
    display: block;
    height: 5rem;

    &--mobile {
      height: 4rem;
      vertical-align: middle;
    }
  }

  &__logo-container {
    background-image: linear-gradient(to bottom right, $orange-60, $red-60);
    padding: 1rem 2rem;
  }

  &__logo-name {
    color: $white-70;
    margin: 0 2.5rem;
    white-space: nowrap;
  }

  &__list-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  &__list-item {
    display: inline-block;
  }

  &__menu {
    background: $black-40;
    display: none;
    padding: 2.5rem;
  }

  &--desktop {
    align-items: center;
    background: $black-40;
    color: $white-60;
    display: flex;
  }

  &--mobile {
    display: none;
  }

  &__mobile-bg--closed {
    background-image: linear-gradient(to bottom right, $orange-60, $red-60);
  }

  &__mobile-bg--open {
    background: $black-40;
  }
}

.nav-link {
  color: $white-70;
  cursor: pointer;
  display: block;
  height: 100%;
  margin: 0 2.5rem;

  &:hover {
    color: $orange-80;
  }

  &--selected {
    color: $orange-80;
    cursor: pointer;
  }
}

@media (max-width: $tablet) {
  .nav--desktop {
    display: none;
  }

  .nav__list-item {
    display: block;
    margin-top: 1rem;
    text-align: center;
  }

  .nav--mobile {
    box-sizing: border-box;
    color: $white-60;
    display: block;
    min-height: 6rem;
    padding: 1rem;
  }

  .nav__menu {
    display: block;
  }

  .logo {
    display: none;
  }
}

@media (max-width: $mobile) {
  .nav__menu {
    padding: 1.5rem;
  }
}
