.tray {
  background: $black-40;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 2px solid $black-40;
  bottom: 0;
  left: 0;
  position: sticky;
  right: 0;
  z-index: 10;

  &__toggle-btn {
    background: $orange-80;
    border: none;
    border-radius: 0 0 1.2rem 1.2rem;
    cursor: pointer;
    display: block;
    padding: 0.7rem 3rem;
    margin: 0 auto 0.5rem auto;

    &:hover {
      background-color: $orange-70;
      box-shadow: inset 0 -0.2rem 0 rgba(84, 89, 94, 0.37);
    }

    &:focus {
      background-color: $orange-50;
      box-shadow: 0 0 0 0.1rem $blue-50;
      outline: none;
    }
  }

  &__toggle-icon {
    margin-right: 1rem;
  }

  &__body {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;

    &--show {
      height: auto;
      max-height: 80vh;
      overflow: visible;
    }
  }

  &__container {
    overflow: auto;
    max-height: 60vh;
    background: $black-50;
    padding: 2.5rem 0;

    &--clear {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 1rem;
    }
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1170px;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 37, 40, 0.55);
    z-index: 2;
  }
}

@keyframes copyBTN {
  75% {
    background-color: $green-50;
  }
  100% {
    background-color: $black-40;
  }
}

.position {
  margin: 5rem 0;

  &__container {
    display: flex;
    margin-top: 1rem;

    &--header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &--num {
      align-items: center;
      background: $white-70;
      border: none;
      border-radius: 12px 0 0 12px;
      display: flex;
      justify-content: center;
      width: 50px;
    }

    &--drop {
      flex-grow: 1;
    }
  }

  &__desc {
    color: $white-50;
    margin-bottom: 0;
    max-width: 65vw;
    padding-right: 1.5rem;
  }

  &__label {
    color: $white-70;
  }

  &__hr {
    border: 2px solid rgba(50, 53, 56, 0.4);
  }

  &__btn {
    white-space: nowrap;
  }

  &__btn-container {
    flex-shrink: 0;
  }

  &__toggle-btn {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    margin-left: 1.5rem;
    color: $red-80;
    cursor: pointer;
  }
}

@media (max-width: 1170px) {
  .tray__wrapper {
    padding: 0 1.5rem !important;
  }
}

.num-hover--on {
  display: none;
}

.position__container--num:hover .num-hover--off {
  display: none;
}

.position__container--num:hover .num-hover--on {
  display: inline;
}

@media (max-width: 750px) {
  .position__container--header {
    flex-direction: column;
  }

  .position__desc {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
}
