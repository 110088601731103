select {
  appearance: none;
  background: $white-70 url("../assets/images/Caret-01.png") no-repeat right
    1.5rem top 1.5rem;
  background-size: 1.5rem;
  border: 0.1rem solid $black-75;
  border-radius: 1.2rem;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0.5rem 5.5rem 0.5rem 1rem;
  min-height: 4.4rem;
}

select:focus {
  box-shadow: 0 0 0 0.1rem $blue-50;
  outline: none;
}

/* Media query for tablet devices */
@media (max-width: $tablet) {
  select {
    min-height: 4.1rem;
  }
}

/* Media query for smartphones */
@media (max-width: $mobile) {
  select {
    min-height: 3.8rem;
  }
}
