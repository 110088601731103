.auto-complete--dropdown {
  max-width: 40rem;
  position: relative;
}

.react-autosuggest__container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  background-color: $white-70;
  border: 0.1rem solid $black-75;
  border-radius: 1.2rem;
  border-right: 0.1rem solid $black-75;
  box-sizing: border-box;
  height: 4.3rem;
  padding: 0 4rem 0 1.5rem;
  width: 100%;
}

.react-autosuggest__input--focused {
  outline: none;
  box-shadow: 0 0 0 0.1rem $blue-50;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  background-color: $white-70;
  border: 0.1rem solid $black-75;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  box-sizing: border-box;
  color: $black-40;
  display: block;
  font-size: 1.8rem;
  line-height: 2.7rem;
  max-height: 50rem;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $white-50;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $white-50;
}

.react-autosuggest__suggestion--highlighted:last-child {
  background-color: $white-50;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}

.search-bar__input {
  position: relative;
}

.auto-complete__clear-btn {
  background-color: $white-70;
  border: none;
  border-bottom: 0.1rem solid $black-75;
  border-top: 0.1rem solid $black-75;
  cursor: pointer;
  height: 4.3rem;
  position: absolute;
  right: 1.5rem;
}

/* Media query for tablet devices */
@media (max-width: $tablet) {
  .auto-complete__clear-btn {
    height: 4rem;
  }

  .react-autosuggest__input {
    height: 4rem;
  }

  .react-autosuggest__suggestions-container--open {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

@media (max-width: $mobile) {
  .auto-complete__clear-btn {
    height: 3.7rem;
  }

  .react-autosuggest__input {
    height: 3.7rem;
  }

  .auto-complete > .react-autosuggest__container {
    max-width: 100%;
  }

  .auto-complete > .react-autosuggest__container:after {
    top: 1.2rem;
  }
}
