.assignment {
  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__card {
    background: $white-70;
    width: 30rem;
    border-radius: 1.2rem;
    box-sizing: border-box;
    margin: 2.5rem 2.5rem 0 0;
  }

  &__card-body {
    padding: 1.5rem;
  }

  &__card-header {
    margin: 0;
  }

  &__card-header-container {
    background: $white-50;
    padding: 1rem 1.5rem;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
  }

  &__card-link {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
}
