/* ################# MEDIA QUERIES VALUES ################# */
$mobile: 450px;
$tablet: 1024px;
$laptop: 1440px;

/* ################# FONT SIZING ################# */
// $desktop-h1-font-size: 2.91rem;
// $desktop-h2-font-size: 2.29rem;
// $desktop-subtitle-font-size: 2.91rem;
// $desktop-lg-font-size: 2.1rem;
// $desktop-md-font-size: 1.8rem;
// $desktop-sm-font-size: 1.6rem;

// $tablet-h1-font-size: 2.59rem;
// $tablet-h2-font-size: 2.04rem;
// $tablet-lg-font-size: 1.9rem;
// $tablet-md-font-size: 1.6rem;
// $tablet-sm-font-size: 1.4rem;

// $mobile-h1-font-size: 2.27rem;
// $mobile-h2-font-size: 1.78rem;
// $mobile-lg-font-size: 1.7rem;
// $mobile-md-font-size: 1.4rem;
// $mobile-sm-font-size: 1.2rem;

$desktop-h1-font-size: 2.91rem;
$desktop-h2-font-size: 2.29rem;
$desktop-subtitle-font-size: 2.91rem;
$desktop-lg-font-size: 1.9rem;
$desktop-md-font-size: 1.6rem;
$desktop-sm-font-size: 1.4rem;

$tablet-h1-font-size: 2.59rem;
$tablet-h2-font-size: 2.04rem;
$tablet-lg-font-size: 1.7rem;
$tablet-md-font-size: 1.4rem;
$tablet-sm-font-size: 1.2rem;

$mobile-h1-font-size: 1.94rem;
$mobile-h2-font-size: 1.53rem;
$mobile-lg-font-size: 1.5rem;
$mobile-md-font-size: 1.2rem;
$mobile-sm-font-size: 1rem;

/* ################# FONT LINE HEIGHTS ################# */
// $desktop-h1-line-height: 4.37rem;
// $desktop-h2-line-height: 3.43rem;
// $desktop-subtitle-line-height: 4.37rem;
// $desktop-lg-line-height: 3.15rem;
// $desktop-md-line-height: 2.7rem;
// $desktop-sm-line-height: 2.4rem;

// $tablet-h1-line-height: 3.88rem;
// $tablet-h2-line-height: 3.05rem;
// $tablet-lg-line-height: 2.85rem;
// $tablet-md-line-height: 2.4rem;
// $tablet-sm-line-height: 2.1rem;

// $mobile-h1-line-height: 3.4rem;
// $mobile-h2-line-height: 2.67rem;
// $mobile-lg-line-height: 2.55rem;
// $mobile-md-line-height: 2.1rem;
// $mobile-sm-line-height: 1.8rem;

$desktop-h1-line-height: 4.37rem;
$desktop-h2-line-height: 3.43rem;
$desktop-subtitle-line-height: 4.37rem;
$desktop-lg-line-height: 2.7rem;
$desktop-md-line-height: 2.4rem;
$desktop-sm-line-height: 2.1rem;

$tablet-h1-line-height: 3.88rem;
$tablet-h2-line-height: 3.05rem;
$tablet-lg-line-height: 2.4rem;
$tablet-md-line-height: 2.1rem;
$tablet-sm-line-height: 1.8rem;

$mobile-h1-line-height: 2.91rem;
$mobile-h2-line-height: 2.29rem;
$mobile-lg-line-height: 2.1rem;
$mobile-md-line-height: 1.8rem;
$mobile-sm-line-height: 1.5rem;

/* ################# COLOR RGB VALUES ################# */
$orange-80: rgb(255, 177, 115);
$orange-70: rgb(254, 151, 69);
$orange-60: rgb(254, 136, 70);
$orange-50: rgb(255, 126, 23);
$orange-40: rgb(173, 75, 0);
$orange-30: rgb(145, 63, 0);
$orange-20: rgb(105, 46, 0);

$red-80: rgb(253, 170, 173);
$red-70: rgb(253, 117, 120);
$red-60: rgb(253, 71, 76);
$red-50: rgb(211, 29, 35);
$red-40: rgb(196, 27, 32);
$red-30: rgb(179, 24, 29);
$red-20: rgb(153, 21, 25);

$yellow-70: rgb(255, 223, 162);
$yellow-60: rgb(255, 208, 115);
$yellow-50: rgb(254, 192, 69);
$yellow-40: rgb(255, 176, 23);
$yellow-30: rgb(231, 153, 0);
$yellow-20: rgb(138, 92, 0);

$black-80: rgb(155, 158, 161);
$black-75: rgb(121, 119, 121);
$black-70: rgb(108, 106, 108);
$black-60: rgb(84, 89, 94);
$black-50: rgb(74, 81, 84);
$black-40: rgb(50, 53, 56);
$black-30: rgb(34, 37, 40);
$black-20: rgb(0, 0, 0);

$white-70: rgb(254, 254, 254);
$white-60: rgb(241, 239, 243);
$white-50: rgb(221, 227, 229);
$white-40: rgb(221, 221, 221);
$white-30: rgb(203, 209, 212);

$blue-60: rgb(199, 220, 252);
$blue-50: rgb(41, 128, 185);

$green-60: rgb(178, 228, 213);
$green-50: rgb(36, 153, 118);
$green-40: rgb(24, 104, 80);

$visited-color: #551a8b;
$active-color: #ee0000;
