.pos__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pos__container-item--grow {
  flex-grow: 2;
}

.pos__container--right {
  display: flex;
  justify-content: flex-end;
}

.pos__mr-25 {
  margin-right: 2.5rem;
}

@media (max-width: $mobile) {
  .pos__btn {
    width: 100%;
    text-align: center;
  }

  .pos__mr-25 {
    margin-right: 0;
  }
}
